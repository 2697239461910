import * as React from "react";
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { orderApiMenuItems, dataLibraryApiMenuItems } from "../constants";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CollectionsIcon from "@mui/icons-material/Collections";

const ListItem = ({ text }) => {
  return (
    <ListItemText
      primary={
        <Typography
          type="body2"
          sx={{
            fontSize: {
              xs: "0.8rem",
              md: "1rem",
            },
          }}
        >
          {text}
        </Typography>
      }
    />
  );
};

const styles = {
  list: {
    paddingLeft: { xs: "20px", sm: "40px", md: "50px", lg: "100px" },
  },
  linkStyle: {
    textDecoration: "unset",
    color: "inherit",
  },
  icon: {
    width: { xs: "0.7em", md: "1em" },
  },
};

const VerticalMenu = (page) => {
  const [selectedIndex, setSelectedIndex] = React.useState(page);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
        display: { xs: "none", lg: "flex", justifyContent: "flex-end" },
      }}
    >
      <List component="nav" aria-label="main mailbox folders">
        <Link to="/" style={styles.linkStyle}>
          <ListItemButton
            selected={selectedIndex === 0}
            onClick={(event) => handleListItemClick(event, 0)}
          >
            <ListItemIcon>
              <HomeIcon sx={styles.icon} />
            </ListItemIcon>
            <ListItem text="Overview" />
          </ListItemButton>
        </Link>
        <Divider />
        {/* <List component="nav" aria-label="secondary mailbox folder"> */}
        <Link to={"/authentication"} style={styles.linkStyle}>
          <ListItemButton
            selected={selectedIndex === 1}
            onClick={(event) => handleListItemClick(event, 1)}
            sx={styles.list}
          >
            <ListItem text={"API Authentication"} />
          </ListItemButton>
        </Link>
        <Link to={"/testing-environment"} style={styles.linkStyle}>
          <ListItemButton
            selected={selectedIndex === 2}
            onClick={(event) => handleListItemClick(event, 2)}
            sx={styles.list}
          >
            <ListItem text={"Testing environment"} />
          </ListItemButton>
        </Link>
        <Link to={"/versions"} style={styles.linkStyle}>
          <ListItemButton
            selected={selectedIndex === 2}
            onClick={(event) => handleListItemClick(event, 3)}
            sx={styles.list}
          >
            <ListItem text={"Versions"} />
          </ListItemButton>
        </Link>
        {/* </List> */}
        <Link to="/order-api" style={styles.linkStyle}>
          <ListItemButton
            selected={selectedIndex === 3}
            onClick={(event) => handleListItemClick(event, 4)}
          >
            <ListItemIcon>
              <AddCircleOutlineIcon sx={styles.icon} />
            </ListItemIcon>
            <ListItem text="Order API" />
          </ListItemButton>
        </Link>

        {orderApiMenuItems.map((item, index) => (
          <Box key={index}>
            <Divider />
            {/* <List component="nav" aria-label="secondary mailbox folder"> */}
            <Link to={item.link} style={styles.linkStyle}>
              <ListItemButton
                selected={selectedIndex === index + 1}
                onClick={(event) => handleListItemClick(event, index + 1)}
                sx={styles.list}
              >
                <ListItem text={item.label} />
              </ListItemButton>
            </Link>
            {/* </List> */}
          </Box>
        ))}
        <Link to="/delivery" style={styles.linkStyle}>
          <ListItemButton
            selected={selectedIndex === 9}
            onClick={(event) => handleListItemClick(event, 8)}
          >
            <ListItemIcon>
              <ForwardToInboxIcon sx={styles.icon} />
            </ListItemIcon>
            <ListItem text="Delivery API" />
          </ListItemButton>
        </Link>
        <Link to="/datalibrary-api" style={styles.linkStyle}>
          <ListItemButton
            selected={selectedIndex === 10}
            onClick={(event) => handleListItemClick(event, 7)}
          >
            <ListItemIcon>
              <CollectionsIcon sx={styles.icon} />
            </ListItemIcon>
            <ListItem text="Data library API" />
          </ListItemButton>
        </Link>
        {dataLibraryApiMenuItems.map((item, index) => (
          <Box key={index}>
            <Divider />
            {/* <List component="nav" aria-label="secondary mailbox folder"> */}
            <Link to={item.link} style={styles.linkStyle}>
              <ListItemButton
                selected={selectedIndex === index + 1}
                onClick={(event) => handleListItemClick(event, index + 1)}
                sx={styles.list}
              >
                <ListItem text={item.label} />
              </ListItemButton>
            </Link>
            {/* </List> */}
          </Box>
        ))}

        <ListItemButton
          selected={selectedIndex === 13}
          onClick={(event) => handleListItemClick(event, 9)}
        >
          <ListItemIcon>
            <IntegrationInstructionsIcon sx={styles.icon} />
          </ListItemIcon>
          <Link to="/integrations" style={styles.linkStyle}>
            <ListItem text="Integrations" />
          </Link>
        </ListItemButton>
      </List>
    </Box>
  );
};
ListItem.propTypes = {
  text: PropTypes.string.isRequired,
};
VerticalMenu.propTypes = {
  page: PropTypes.number.isRequired,
};
export default VerticalMenu;
