import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import logo from "./../images/completeLogo/Globhe_logotype_2022_black.png";
import theme from "../theme";
import { Link } from "gatsby";
import { expandableMenuItem, fixedPagesMenu } from "../constants";

const styles = {
  root: {
    backgroundColor: `${theme.palette.primary.palePistachio}`,
    marginBottom: "50px",
  },
  image: {
    width: { xs: "80px", lg: "150px" },
  },
  text: {
    mr: 2,
    fontWeight: 100,
    paddingLeft: {
      xs: "10px",
      lg: "20px",
    },
    fontSize: {
      xs: "1rem",
      lg: "1.4rem",
    },
    color: theme.palette.black.darkSeaBlack,
  },
  menu: {
    flexGrow: 1,
    display: { xs: "flex", md: "none", justifyContent: "flex-end" },
  },
  menuItem: {
    display: { xs: "block", md: "none" },
  },
  boxMenu: {
    flexGrow: 1,
    display: { xs: "none", md: "flex", justifyContent: "flex-end" },
  },
  linkStyle: {
    textDecoration: "unset",
    color: "inherit",
  },
  button: {
    my: 2,
    color: theme.palette.black.darkSeaBlack,
    display: "block",
  },
  headerBox: {
    marginLeft: {
      xs: "10px",
      lg: "20px",
    },
    borderLeft: `1px solid ${theme.palette.black.darkSeaBlack}`,
    height: { xs: "40px", lg: "50px" },
  },
};

const HorizontalMenu = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static" style={styles.root}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box component="img" sx={styles.image} src={logo} alt="GLOBHE logo" />
          <Box component="div" sx={styles.headerBox} />
          <Typography variant="h5" noWrap component="div" sx={styles.text}>
            Developers
          </Typography>
          <Box sx={styles.menu}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={styles.menuItem}
            >
              {expandableMenuItem.map((page, index) =>
                page.external ? (
                  <a
                    href={page.link}
                    style={styles.linkStyle}
                    key={index}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">{page.label}</Typography>
                    </MenuItem>
                  </a>
                ) : (
                  <Link to={page.link} style={styles.linkStyle} key={index}>
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">{page.label}</Typography>
                    </MenuItem>
                  </Link>
                )
              )}
            </Menu>
          </Box>

          <Box sx={styles.boxMenu}>
            {fixedPagesMenu.map((page, index) =>
              page.external ? (
                <a
                  href={page.link}
                  style={styles.linkStyle}
                  key={index}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button onClick={handleCloseNavMenu} sx={styles.button}>
                    {page.label}
                  </Button>
                </a>
              ) : (
                <Link
                  to={page.link}
                  style={styles.linkStyle}
                  key={index}
                  external={page.external}
                >
                  <Button onClick={handleCloseNavMenu} sx={styles.button}>
                    {page.label}
                  </Button>
                </Link>
              )
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default HorizontalMenu;
