export const advancedOptionsTableHead = ["spec", "type", "GLOBHE standard"];
export const orderStructureV2TableHead = [
  "field",
  "description",
  "when to see it",
];

export const advancedOptionsTableRow = [
  {
    item: "flightHeight",
    description: "integer between 10 and 120",
    complementaryInfo: "110m AGL",
  },
  {
    item: "frontOverlap",
    description: "integer between 1 and 99",
    complementaryInfo: "75%",
  },
  {
    item: "sideOverlap",
    description: "integer between 1 and 99",
    complementaryInfo: "80%",
  },
  {
    item: "spatialResolution",
    description: "integer",
    complementaryInfo: "2-2.5 cm/px",
  },
  {
    item: "droneType",
    description: "string",
    complementaryInfo: "GLOBHE will select what is available",
  },
  {
    item: "sensors",
    description: "string",
    complementaryInfo: "RGB",
  },
  {
    item: "crs",
    description: "string",
    complementaryInfo: "WGS 84 / EPSG: 4326 / Meters",
  },
  {
    item: "processingSoftware",
    description: "string",
    complementaryInfo: "depends on the operator",
  },
  {
    item: "verticalAccuracy",
    description: "integer",
    complementaryInfo: "up to 10 cm when GCPs are used",
  },
  {
    item: "gcp //(ground control points)",
    description: "boolean",
    complementaryInfo: "false",
  },
];

export const orderStructureV2TableRow = [
  {
    item: "status",
    description:
      "represents the status of the order. See below for a details of all available statuses",
    complementaryInfo: "always",
  },
  {
    item: "instruction",
    description:
      "this is an explanation for you to guide you through the next steps of the ordering flow",
    complementaryInfo: "always",
  },
  {
    item: "orderDetails",
    description:
      "contains the all details that are related to the order itself",
    complementaryInfo: "always",
  },
  {
    item: "quotes",
    description:
      "represents the details of all the quotes that you received and you haven't reacted to, i.e. not accepted and not rejected yet",
    complementaryInfo: "once a quote has been generated by GLOBHE",
  },
  {
    item: "rejectedQuotes",
    description:
      "represents the details of all the quotes that you you have rejected",
    complementaryInfo:
      "once you reject a quote. It will disappear once you accept another quote",
  },
  {
    item: "acceptedQuote",
    description: "represents the details of the quote that you have accepted",
    complementaryInfo: "after accepting the quote",
  },
  {
    item: "orderNotFeasible",
    description:
      "contains the details on why the order is not feasible with photo proof if available",
    complementaryInfo: "when the operator flags problems in the mission",
  },
  {
    item: "currentFlyingDate",
    description: "shows the date when the operator will capture the data",
    complementaryInfo: "for status 'flyingDateSet'",
  },
  {
    item: "prevFlightDate",
    description:
      "shows the first date when the operator was planning capture the data",
    complementaryInfo:
      "when the pilot has rescheduled the flying date, i.e. for status 'flyingDateRescheduled'",
  },
  {
    item: "collectedData",
    description:
      "contains the final data, both jpeg and your chosen processed data type",
    complementaryInfo: "for status 'completed'",
  },
];

export const orderTableHead = ["mapTypes", "Description"];

export const orderTableRows = [
  { item: "_2dMap", description: "represents a 2D Orthomosaic Map" },
  {
    item: "_3dMap",
    description: "represents a 3D model or point cloud",
  },
  {
    item: "elevationMap",
    description: "represents a topographic Map with contour values",
  },
  {
    item: "digitalTerrainModel",
    description: "represents a digital terrain model",
  },
  {
    item: "digitalSurfaceModel",
    description: "represents a digital surface model",
  },
  { item: "thermalMap", description: "represents a thermal Map" },
  {
    item: "ndvi",
    description: "represents a plant health map made through an NDVI algorithm",
  },
  {
    item: "multispectral",
    description: `multispectral data captures light across specific wavelengths 
    beyond the visible spectrum, such as red, green, blue, red edge (RE), and 
    near-infrared (NIR) bands`,
  },
  {
    item: "streamedData",
    description: "represents a Live streamed data (video footage)",
  },
  {
    item: "raw",
    description:
      "represents Raw data/photos in .dng format straight from the drone and that have not been processed into another product",
  },
  {
    item: "panoramas",
    description:
      "represents 360 panoramas taken from a single point with a drone",
  },
  {
    item: "LiDAR",
    description:
      "represents precise 3D representations of objects and environments visualised in a point cloud. It is captured using laser light to measure distance",
  },
];

export const orderStatusHead = ["status", "Description"];

export const orderStatusRowsV1 = [
  {
    item: "reviewing",
    description:
      "the order is being reviewed by GLOBHE admin before being approved",
  },
  {
    item: "pendingPilot",
    description:
      "the order has been sent to drone operators but data capture has not started yet",
  },
  {
    item: "active",
    description: "data capture has started by a local drone operator",
  },
  {
    item: "validating",
    description:
      "the data has been uploaded to crowddroning and is being validated by an admin",
  },
  {
    item: "completed",
    description:
      "the data has been sent to the client and the order is now complete",
  },
  {
    item: "rejected",
    description: "the order has been rejected by GLOBHE ",
  },
  {
    item: "cancelled",
    description: "the order has been cancelled by the client",
  },
  {
    item: "deleted",
    description: "the order has been deleted by admin",
  },
];
export const orderStatusRowsV2 = [
  {
    item: "reviewing",
    description:
      "An order has been place and the order is being reviewed by GLOBHE. You will receive a quote very soon for you to approve or decline",
  },
  {
    item: "quoteReceived",
    description:
      "You have received a quote for your order for you to accept or decline",
  },
  // {
  //   item: "confirmed",
  //   description:
  //     "You have accepted your quote. GLOBHE will now send this order to nearby operators",
  // },
  {
    item: "pendingOperator",
    description:
      "The order is now confirmed and has been sent to drone operators but no operator has been assigned yet.",
  },
  {
    item: "operatorAssigned",
    description:
      "An operator has been assigned to your order. Data capture will start asap once the operator will choose a date",
  },
  {
    item: "orderNotFeasible",
    description:
      "The operator has indicated that there are problem in your order and they are unable to capture the data as per your requirements",
  },
  {
    item: "flyingDateSet",
    description: "The operator has decided when he/she will capture the data",
  },
  // {
  //   item: "missionFeasible",
  //   description:
  //     "The operator has reviewed all the requirementsfor this order and have chosen a date to capture the data",
  // },
  // {
  //   item: "flyingDateRescheduled",
  //   description: "The operator has resecheduled the flying date for your order",
  // },
  // {
  //   item: "dataCollected",
  //   description:
  //     "The operator has captured your data and will upload it to the order page soon",
  // },
  // {
  //   item: "notAbleToCollectData",
  //   description:
  //     "The operator was not able to collect data due to problems on site",
  // },
  // {
  //   item: "validating",
  //   description:
  //     "the data has been uploaded to crowddroning and is being validated by an admin",
  // },
  {
    item: "completed",
    description:
      "the data has been sent to the client and the order is now complete",
  },
  {
    item: "rejected",
    description: "the order has been rejected by GLOBHE ",
  },
  {
    item: "cancelled",
    description: "the order has been cancelled by the client",
  },
  {
    item: "deleted",
    description: "the order has been deleted by admin",
  },
];
export const orderApiMenuItems = [
  {
    link: "/create-order",
    label: "Create Order",
  },
  {
    link: "/list-order",
    label: "List Orders",
  },
  {
    link: "/get-single-order",
    label: "Get Details About a Single Order",
  },
  {
    link: "/accept-reject-quotes",
    label: "Accept and reject quotes",
  },
  {
    link: "/update-order",
    label: "Update Order",
  },
  {
    link: "/cancel-order",
    label: "Cancel Order",
  },
];

export const dataLibraryApiMenuItems = [
  {
    link: "/datalibrary-api/list-purchased-datasets",
    label: "List purchased datasets",
  },
  {
    link: "/datalibrary-api/single-dataset",
    label: "Get details about a single dataset",
  },
];
export const expandableMenuItem = [
  {
    link: "/",
    label: "Overview",
  },
  {
    link: "/authentication",
    label: "Authentication",
  },
  {
    link: "/testing-environment",
    label: "Testing environment",
  },
  {
    link: "/versions",
    label: "Versions",
  },
  {
    link: "/order-api",
    label: "Order API",
  },
  ...orderApiMenuItems,
  {
    link: "/delivery",
    label: "Delivery",
  },
  {
    link: "/datalibrary-api",
    label: "Data library API",
  },
  ...dataLibraryApiMenuItems,
  {
    link: "/integrations",
    label: "Integrations",
  },
  {
    link: "https://globhe.com/crowddroning-products",
    label: "Product Example",
    external: "external",
  },
  {
    link: "https://globhe.com/",
    label: "Back to GLOBHE",
    external: "external",
  },
];

export const fixedPagesMenu = [
  {
    link: "/",
    label: "API",
  },
  {
    link: "https://globhe.com/crowddroning-products",
    label: "Product Example",
    external: "external",
  },
  {
    link: "https://globhe.com/",
    label: "Back to GLOBHE",
    external: "external",
  },
];

export const mapTypesOption = [
  "_2dMap",
  "_3dMap",
  // "elevationMap",
  "digitalTerrainModel",
  "digitalSurfaceModel",
  "topographicMap",
  "thermalMap",
  "ndvi",
  "multispectral",
  "streamedData",
  "originalPhotos",
];

export const missionMapTypes = [
  {
    label: "2D Orthomosaic Map",
    value: "_2dMap",
    description:
      "The single images captured by the drones are stitched together into a single large high-quality 2D orthomosaic map. A 2D map can be utilized across a range of industries, including construction and infrastructure, and used in all modeling and GIS software. Based on the capturing configuration, the resolution is up to a few mm/px. Zoom in and rotate this map to see the high-resolution images and the big difference they can make when compared to satellites",
    fileFormat: "GeoTIFF, Source Images (ZIP), JPG, PDF.",
    fileExtension: [".tiff", ".zip", ".tif"],
  },
  {
    label: "3D model or point cloud",
    value: "_3dMap",
    description:
      "Similar to orthomosaic maps, drone images are stitched together and create a high-resolution 3D model represented by a 3D mesh surface or a point cloud. The latter is a 3D visualization of thousands or even millions of georeferenced points. Point clouds provide high-resolution data and give you an interactive 3D model with real-world imagery and easy modification in 3D modeling software. Point clouds can also be used to develop digitized models of structures for CAD and BIM-related purposes, which is at the forefront of modern construction.",
    fileFormat: "obj, LAS (Point Cloud), ASCII .xyz, autodesk .rcp, GEOTIFF",
    fileExtension: [".obj", ".mtl", ".jpg", ".las", ".xyz", ".rcp", ".zip"],
  },
  {
    label: "Digital terrain model",
    value: "digitalTerrainModel",
    description:
      "The terrain surface map reveals the ground surface without any objects represented. It allows detailed terrain analysis, planning construction, hydrological applications, and modeling environmental risks. The resolution of these DEMs can reach up to a few cm according to the drone configuration. Zoom in and rotate this map to see the high-resolution model we can provide.",
    fileFormat:
      "GeoTIFF, Raw Elevation Values (DTM),Contour DXF (AutoCAD),Contour SHP (Shapefile), JPG, PDF.",
    fileExtension: [".tiff", ".zip", ".shp", ".dxf", ".tif"],
  },
  {
    label: "Digital surface model",
    value: "digitalSurfaceModel",
    description:
      "Represents elevation data captured by a drone, including all the surfaces it sees, such as trees, equipment, and other structures. It is different than the digital terrain model as it shows objects and vegetation. This model is ideal for the agricultural industry and other industries, as it allows for vegetation inspection and management. Depending on the mission configuration, the resolution can be down to 1 cm. The data can also create accurate volumetric calculations from these elevation maps.",
    fileFormat:
      "GeoTIFF,Raw Elevation Values (DTM),Contour SHP (Shapefile),Contour DXF (AutoCAD),JPG, PDF.",
    fileExtension: [".tiff", ".zip", ".shp", ".dxf", ".tif"],
  },
  {
    label: "Topographic map with contour lines",
    value: "topographicMap",
    description:
      "Topographic maps conventionally show the topography utilizing contour lines. The latter are curves connecting points of equal elevation above a given level, such as the mean sea level. The contour interval of a contour map is the difference in elevation between successive contour lines. High-resolution topographic maps are very useful when investigating natural hazard assessment, slope stability, large construction projects, hydrogeology, and monitoring of underground resources. In addition, differentiated topography resulting from surface motion is critical in understanding tectonic and volcanic activities and their resulting hazards. Resolution can reach less than 1 cm depending on the mission configuration.",
    fileFormat: "Contour SHP (Shapefile),Contour DXF (AutoCAD), JPG, PDF.",
    fileExtension: [".tiff", ".zip", ".shp", ".dxf", ".jpg", ".tif"],
  },
  {
    label: "Thermal Map",
    value: "thermalMap",
    description:
      "Survey with a thermal camera and quickly identify targets with abnormal heat signatures. Thermal surveys can help identify heat loss to improve energy efficiency, can locate water leaks or moisture ingress or faults with wiring and other electrical components. They are also very beneficial in forestry as they help identify early fire risks before they develop and spread.",
    fileFormat: "GeoTIFF, zip.",
    fileExtension: [".tiff", ".zip", ".tif"],
  },
  {
    label: "Multispectral",
    value: "multispectral",
    description: `Multispectral drone sensors provide detailed insights into crop 
    health by capturing images across various light spectrums. They utilize advanced 
    algorithms, like NDVI, to analyze these images and distinguish healthy plants 
    from stressed or diseased ones. This technology is crucial for precision 
    agriculture, allowing farmers to efficiently manage resources and maximize yields 
    by identifying issues early and accurately.
      `,
    fileFormat: "GeoTIFF, Shapefile, JPG, PDF.",
    fileExtension: [".tiff", ".zip", ".tif"],
  },
  {
    label: "Plant health (NDVI maps)",
    value: "ndvi",
    description:
      "Plant health maps explore agricultural data even more deeply by visualizing variability within a field to quantify damage and predict yields by showing the area ​within a specific range. Uses a multi-spectral camera or NDVI algorithm to extract plant health information from RGB images.",
    fileFormat: "GeoTIFF, Shapefile, JPG, PDF.",
    fileExtension: [".tiff", ".zip", ".tif"],
  },
  {
    label: "Live streamed data (video footage)",
    value: "streamedData",
    description:
      "It is a live video stream of what the drone is capturing during flight. It provides a secure real-time video stream from a drone's eye view to team members, decision-makers, or emergency response teams.",
    fileFormat: [".mp4, live broadcast", ".mov", ".avi"],
    fileExtension: [
      ".mp4",
      ".mov",
      ".avi",
      ".avchd",
      ".mkv",
      ".flv",
      ".f4v",
      ".swf",
    ],
  },
  {
    label: "Raw data/photos",
    value: "raw",
    description:
      "DNG 'Digital Negative Image' is a raw, untouched, and unprocessed image recorded directly from a camera’s sensor. Optimal format in case you want to edit the images in house before you processing them.",
    fileFormat: "georeferenced images in .dng",
    fileExtension: [".dng"],
  },
  {
    label: "360 panoramas",
    value: "panoramas",
    description:
      "A 360-degree photo is a controllable panoramic image that surrounds the original point from which the shot was taken. 360-degree photos simulate being in the shoes of a photographer and looking around to the left, right, up and down as desired as well as zooming in to see the details. It is a great tool to see the perspectives and looking into a landscape from all angles.",
    fileFormat: "jpg",
    fileExtension: [".jpg", ".png", ".jpeg"],
  },
  {
    label: "LiDAR",
    value: "LiDAR",
    description:
      "Lidar, which stands for Light Detection and Ranging, is a remote sensing method that uses light in the form of a pulsed laser to measure ranges (variable distances) to the Earth. These light pulses—combined with other data recorded by the airborne system — generate precise, three-dimensional information about the shape of the Earth and its surface characteristics. A lidar instrument principally consists of a laser, a scanner, and a specialized GPS receiver. Airplanes and helicopters are the most commonly used platforms for acquiring lidar data over broad areas. Two types of lidar are topographic and bathymetric. Topographic lidar typically uses a near-infrared laser to map the land, while bathymetric lidar uses water-penetrating green light to also measure seafloor and riverbed elevations",
    fileFormat: ".LAS, .LAZ, .obj, .ply, .txt, .e57, .pts, .u3d, .cl3, .dxf",
    fileExtension: [".las"],
  },
  {
    label: "Base Files",
    value: "originalPhotos",
  },
];

export const endpoints = [
  {
    code: "deliveryDeadline",
    description:
      "represents the deadline by which you want to receive the data",
  },
  {
    code: "locationMap",
    description:
      "represents the coordinates of the polygon of the area of interest. it should consist of an array of lat and lng, with each lat and lng corresponding to an edge of the polygon.",
  },
  {
    code: "description",
    description:
      "represents a detailed description of what the clients needs. It is recommended to write as much description as possible to detail the requirements of the client in terms of resolution, equipments, product, safety concerns...",
  },
  {
    code: "orderName",
    description:
      "represents the name of the order that you are placing. It should not be more than 20 characters",
  },
];
export const listOrderEndpoints = [
  {
    code: "fileDataType",
    description:
      "represents the delivery file data type that the client will receive. For example 2D orthomosaics maps, thermal maps... Check the Create Order for more info on the available delivery data types",
  },
  {
    code: "status",
    description:
      "Represents the status of the mission. The availables statuses are listed below.",
  },
  {
    code: "limit",
    description:
      "represents the maximum number of entries to be included in the response. The default is 10.",
  },
  {
    code: "page",
    description:
      "this is the pagination in the response in case the limit above is chosen. The default is 0.",
  },
];
export const optionalEndpoints = [
  {
    code: "clientEmail",
    description:
      "represents the email address that the notification will be sent to. If not inserted, the email address associated with the API key will be used.",
  },
  {
    code: "clientName",
    description:
      "represents the client name. If not inserted, the name associated with the API key will be used.",
  },
  {
    code: "companyName",
    description:
      "represents the company name. If not inserted, the company name associated with the API key will be used.",
  },
  {
    code: "notifications",
    description:
      "to specify if the client wants to be notified via a webhook and via email when the status of the order changes.",
  },
  {
    code: "otherMapType",
    description:
      "in case the map type is not the one that is listed above, you can insert it here.",
  },
  {
    code: "pointOfContact",
    description:
      "to specify the name and phone number of the person in charge on site, for the operators to call once they are on site. Include pocName and pocPhone in the request.",
  },
  {
    code: "advancedOption",
    description:
      "to specify the specs for data capturing if any. If none is chosen, the standards specs will be applied. See below for details.",
  },
];
export const dataLibraryEndpoints = [
  {
    code: "fileDataType",
    description:
      "represents the data delivery product that is available. See below for details on the available data delivery products. If none is selected, only the baseFiles will be returned in the response. This can contain up to 10 filters at a time.",
  },
  {
    code: "page",
    description:
      "This parameter is used to fetch the records of particular page and it starts from 0. Each page typically contains a predefined number of items or records. By incrementing the page value, you can navigate through the available pages of data until you have retrieved all the desired information. If not specified, the page will return 0.",
  },
  {
    code: "limit",
    description:
      "itrefers to the maximum number of items or records that can be returned in a single API response. It allows you to control the quantity of data you want to retrieve from the API at once. If not specified the limit will be 10.",
  },
];
export const acceptQuoteEndpoints = [
  {
    code: "status",
    description:
      "Represents the status of the order. If you received a quote, the status will always be 'quoteReceived'",
  },
  {
    code: "instruction",
    description: "Describes the steps that should be taken from your side",
  },
  {
    code: "quotes",
    description:
      "Contains the list of quotes that you receive. You might receive more than one quote if you requested to. These quotes are neither accepted nor rejected from your side. ",
  },
  {
    code: "rejectedQuotes",
    description: "Contains the list of quotes that you rejected.",
  },
  {
    code: "orderDetails",
    description:
      "Contains all the order information that you have placed earlier.",
  },
];
export const quoteEndpoints = [
  {
    code: "quoteId",
    description: "Represents the unique ID of the quote in question.",
  },
  {
    code: "description",
    description:
      "Contains description of information from GLOBHE about how or why they issued such a quote. Could be beneficial in case you received multiple quotes.",
  },
  {
    code: "deliveryBy",
    description:
      "Refers to the maximum number of days within which your data will be provided.",
  },
  {
    code: "priceBeforeDiscount",
    description: "Indicates the order price without any discounts.",
  },
  {
    code: "currency",
    description:
      "The currency of the price shown above amount. You can change your currency in your profile.",
  },
  {
    code: "discountProvided",
    description: "Indicates the discount in percent in case it is applied.",
  },
  {
    code: "reasonForDiscount",
    description:
      "Describes the reason why there is a discount in case a discount is applied.",
  },
  {
    code: "finalPrice",
    description: "The final price of this order with discount.",
  },
  {
    code: "expiryDate",
    description:
      "Indicates the date and time when this quote will expire. You will not be able to accept or reject this quote after the expiry date.",
  },
];
export const listOrderJsonExample = {
  limit: 10,
  page: 0,
  status: "active",
};
export const acceptQuoteExample = {
  status: "accept",
};
export const orderJsonExample = {
  deliveryDeadline: "2022-10-01",
  locationMap: [
    {
      lat: -26.652673646040256,
      lng: -6.2430225172747384,
    },
    {
      lat: -26.713196983259046,
      lng: 2.2772415512977204,
    },
    {
      lat: 17.441499309513688,
      lng: 108.58612411318285,
    },
  ],
  mapTypes: ["_2dMap", "elevationMap"],
  description:
    "A detailed description of the data capture requirements goes here",
  clientEmail: "foo@foo.com",
  clientName: "Darth Vader",
  companyName: "The First Galactic Empire",
  orderName: "Site 001 Paris",
  notifications: {
    webhook:
      "http://YKgmigAHHvGCfxhGJqpjcuY.sufbf1IWOF3R.encEUQoZ5fWDGiZ6wlRi2hFLgHsWzf58-Asm9",
    email: false,
  },
  pointOfContact: {
    pocName: "Katniss Everdeen",
    pocPhone: "123456",
  },
  otherMapType: "in Ut amet",
  advancedOption: {
    flightHeight: 60,
    gcp: true,
  },
};
export const deliveryJsonExample = {
  fileDataType: ["_2dMap", "thermalMap"],
};
export const orderResponseJsonExample = {
  id: "adipisicing123456789",
  areaSize: 85301325.0620738,
  clientEmail: "foo@foo.com",
  deliveryDeadline: "2022-10-01",
  clientName: "Darth Vader",
  companyName: "The First Galactic Empire",
  deliveryFiles: [
    "https://QASqjdhKvlxiCNNJwUHJrFmzLBWTjh.ockrTYSZnMhrJl9QM3uEOKjrjpVdpQTqhvvDQusfv",
    "http://l.gmpdgsdfgjkGhjFRyDSbhjTYYGHJghjTYvUVuVYuVjSDSdnmb",
    "http://l.gmpwkRAZCHIVptOfZ3DSDA56GHJbncGbnFSDAMSDbnmSDSDWEhjkqwVYUDSghjadA",
    "http://l.gmpwkRAZCHIVptOfZ3HJWOpHuubnmFHjkEhjVuiopWbnAhjkASdkjhJJH",
  ],
  description:
    "A detailed description of the data capture requirements goes here",
  orderName: "Site 001 Paris",
  orderPrice: 44927494.15693178,
  orderStatus: "reviewing",
  orderRejectionReason: "anim dolor dolore ex",
  locationMap: [
    {
      lat: 47.40430222489124,
      lng: -33.278262875341284,
    },
    {
      lat: -17.70925800990831,
      lng: 144.48954366869725,
    },
  ],
  mapTypes: ["_2dMap", "elevationMap"],
  notifications: {
    webhook: "https://uLDIaEmhpasAXxN.xfsYQI7WVtzY96ZiVGIL8uvEwUid+C7Z,A,.5Or",
    email: true,
  },
  pointOfContact: {
    pocName: "Katniss Everdeen",
    pocPhone: "123456",
  },
  otherMapType: "ut incididunt labore",
  advancedOption: {
    flightHeight: 60,
    gcp: true,
  },
};

export const listJsonExample = {};

export const updateJsonExample = {
  clientEmail: "foo@foo.com",
  clientName: "Luke Skywalker",
  companyName: "Jedi High Council",
  notifications: {
    webhook: "http://dDX.eojldzcraJs24zoGRPaaeKraeRyNtYjph6nnu8H4",
    email: false,
  },
};

export const updateResponseJsonExample = {
  id: "123456789",
  areaSize: 49468049.34829436,
  clientEmail: "foo@foo.com",
  deliveryDeadline: "1955-09-19",
  clientName: "Luke Skywalker",
  companyName: "Jedi High Council",
  description: "pariatur fugiat",
  orderPrice: 90302877.53317097,
  orderStatus: "reviewing",
  locationMap: [
    {
      lat: 89.59607725985146,
      lng: 131.18019461249787,
    },
    {
      lat: 73.2426077887128,
      lng: 102.17697778830319,
    },
  ],
  mapTypes: ["ndvi", "digitalTerrainModel"],
  notifications: {
    webhook: "http://dDX.eojldzcraJs24zoGRPaaeKraeRyNtYjph6nnu8H4",
    email: false,
  },
  otherMapType: "incididunt non eu eiusmod",
};

export const deleteResponseJsonExample = {
  id: "123456789",
  areaSize: 49468049.34829436,
  clientEmail: "Rx7ykfyKBN0OSg@MdMbVlqohZhCmqdC.dp",
  deliveryDeadline: "1955-09-19",
  clientName: "Lord Palpatine",
  companyName: "The Dark Side",
  description: "pariatur fugiat",
  orderPrice: 90302877.53317097,
  orderStatus: "cancelled",
  locationMap: [
    {
      lat: 89.59607725985146,
      lng: 131.18019461249787,
    },
    {
      lat: 73.2426077887128,
      lng: 102.17697778830319,
    },
  ],
  mapTypes: ["ndvi", "digitalTerrainModel"],
  notifications: {
    webhook: "https://kvpmHNa.fuvcdQjX0QTKSyVs65ZxUpSuCyVSy",
    email: true,
  },
  otherMapType: "incididunt non eu eiusmod",
};

export const getSingleOrderResponseJson = {
  id: "123456789",
  areaSize: 49468049.34829436,
  clientEmail: "han-solo@millenium-falcon.com",
  deliveryDeadline: "2024-09-19",
  clientName: "Han Solo",
  companyName: "Millenium Falcon",
  description: "pariatur fugiat",
  orderPrice: 1000,
  orderStatus: "active",
  orderRejectionReason: "",
  locationMap: [
    {
      lat: 89.59607725985146,
      lng: 131.18019461249787,
    },
    {
      lat: 73.2426077887128,
      lng: 102.17697778830319,
    },
  ],
  mapTypes: ["ndvi", "digitalTerrainModel"],
  notifications: {
    webhook: "https://kvpmHNa.fuvcdQjX0QTKSyVs65ZxUpSuCyVSy",
    email: true,
  },
  otherMapType: "incididunt non eu eiusmod",
};
export const getSingleOrderResponseJsonV2 = {
  status: "quoteReceived",
  instruction:
    "Your quote has been received. Please review it and take action by either accepting or rejecting your quote. Once you accept the quote, the drone operators will start capturing your data.",
  quotes: [
    {
      quoteId: "20240628-2",
      quoteName: "quote 3",
      description:
        "This quote covers your order of 100 hectares of 2D orthomosaics at 3 cm resolution in Namibia. No discount is used.",
      deliveryBy: "20 days",
      priceBeforeDiscount: 1800,
      currency: "USD",
      discountProvided: "0%",
      reasonForDiscount: null,
      finalPrice: 1800,
      expiryDate: "2024-03-06T10:59:30.730+01:00",
    },
  ],
  rejectedQuotes: [
    {
      quoteId: "20240628-1",
      quoteName: "quote 1",
      description:
        "This quote covers your order of 100 hectares of 2D orthomosaics at 10 cm resolution in Namibia. No discount is used.",
      deliveryBy: "30 days",
      priceBeforeDiscount: 1000,
      currency: "USD",
      discountProvided: "0%",
      reasonForDiscount: null,
      finalPrice: 1000,
      expiryDate: "2024-03-11T09:38:27.664+01:00",
    },
  ],
  id: "20230628arte",
  areaSize: 49468049.34829436,
  clientEmail: "han-solo@millenium-falcon.com",
  deliveryDeadline: "2024-09-19",
  clientName: "Han Solo",
  companyName: "Millenium Falcon",
  description:
    "A description of the order and the purpose is added here to guide the operator.",
  orderPrice: "",
  orderStatus: "inReview",
  orderRejectionReason: "",
  locationMap: [
    {
      lat: 89.59607725985146,
      lng: 131.18019461249787,
    },
    {
      lat: 73.2426077887128,
      lng: 102.17697778830319,
    },
  ],
  mapTypes: ["ndvi", "digitalTerrainModel"],
  notifications: {
    webhook: "https://kvpmHNa.fuvcdQjX0QTKSyVs65ZxUpSuCyVSy",
    email: true,
  },
  otherMapType: "",
};

export const getQuoteAcceptResponse = {
  status: "Quote accepted",
  quoteDetails: {
    quoteId: "20230628arte-2",
    quoteName: "quote 2",
    description:
      "This quote covers your order of 100 hectares of 2D orthomosaics at 3 cm resolution in Namibia. No discount is used.",
    deliveryBy: "20",
    priceBeforeDiscount: 1800,
    currency: "USD",
    discountProvided: "0%",
    reasonForDiscount: null,
    finalPrice: 1800,
    expiryDate: "2024-03-06T10:59:30.730+01:00",
  },
};

export const deliveryResponseJson = {
  id: "123456789",
  areaSize: 49468049.34829436,
  clientEmail: "foo@foo.com",
  deliveryDeadline: "2022-09-19",
  clientName: "Obi-Wan Kenobi",
  companyName: "Jedi High Council",
  deliveryFiles: {
    baseFiles: [
      "http://nuxOmB.dyayq3Say,IZXYrKkQ9U9z",
      "http://ilqlD.vpqxkoCyco0clK6w30DTagtTUhJPW.+5LKRSe0KxJoXwnstEKpl",
      "http://sdfsd.ertedcv,etertcvjkl234jLKjsnJH3jilS",
      "http://xnm.342jlLJ3wer90sdjfs,hjHJKdsjklIfspO",
      "http://xcv4h.Jkls3Jkl4nm,JklsdfJLSkfjEWIcnjSjkls",
      "http://nuxOmB.jklsdfJKLEonkHS,hkjsfsHKLJDFnsdfs",
    ],
    processedData: {
      topographicMap: ["http://nuxOmB.jklsdfJKLEonkHS,hkjsfsHKLJDFnsdwerwfs"],
      _2dMap: ["http://nuxOmB.jklsdfJKLEonkHS,h22sd2dfsHKLJDFnsdwerwfs"],
      thermalMap: ["http://nuB.jJKertffnkHS,hkjsfsHKLJDFnsdwerwfs"],
    },
  },
  description: "pariatur fugiat",
  orderPrice: 90302877.53317097,
  orderStatus: "completed",
  locationMap: [
    {
      lat: 89.59607725985146,
      lng: 131.18019461249787,
    },
    {
      lat: 73.2426077887128,
      lng: 102.17697778830319,
    },
  ],
  mapTypes: ["ndvi", "digitalTerrainModel"],
  notifications: {
    webhook: "https://kvpmHNa.fuvcdQjX0QTKSyVs65ZxUpSuCyVSy",
    email: true,
  },
  otherMapType: "incididunt non eu eiusmod",
};
export const deliveryResponseJson2 = {
  id: "123456789",
  areaSize: 49468049.34829436,
  clientEmail: "foo@foo.com",
  deliveryDeadline: "2022-09-19",
  clientName: "Obi-Wan Kenobi",
  companyName: "Jedi High Council",
  deliveryFiles: {
    baseFiles: [],
    processedData: {
      _2dMap: ["http://nuxOmB.jklsdfJKLEonkHS,h22sd2dfsHKLJDFnsdwerwfs"],
      thermalMap: ["http://nuB.jJKertffnkHS,hkjsfsHKLJDFnsdwerwfs"],
    },
  },
  description: "pariatur fugiat",
  orderPrice: 90302877.53317097,
  orderStatus: "completed",
  locationMap: [
    {
      lat: 89.59607725985146,
      lng: 131.18019461249787,
    },
    {
      lat: 73.2426077887128,
      lng: 102.17697778830319,
    },
  ],
  mapTypes: ["ndvi", "digitalTerrainModel"],
  notifications: {
    webhook: "https://kvpmHNa.fuvcdQjX0QTKSyVs65ZxUpSuCyVSy",
    email: true,
  },
  otherMapType: "incididunt non eu eiusmod",
};
export const dataLibraryListJson = {
  fileDataType: ["_2dMap", "digitalTerrainModel"],
  page: 0,
  limit: 10,
};

export const dataLibraryResponseJson = [
  {
    id: "9orj8k23",
    datasetName: "Dataset name",
    categories: ["forestry", "orthomosaics"],
    description:
      "This is a brief description of this datasets and what it contains",
    sdgGoals: ["goal1", "goal2"],
    locationLabel: "Oriximiná - State of Pará, 68270-000, Brazil",
    locationMap: [
      {
        lng: -56.28995416860816,
        lat: -1.1943083787212674,
      },
      {
        lng: -56.20618341665504,
        lat: -1.1805784177529681,
      },
      {
        lng: -56.19245050649879,
        lat: -1.2217680940951903,
      },
      {
        lng: -56.26660822134254,
        lat: -1.2368708183180714,
      },
    ],
    dateCompleted: "2021-02-28",
    deliveryFiles: {
      baseFiles: ["https://someURL", "https://someURL", "https://someURL"],
      processedData: {
        _2dMap: ["https://someURL"],
      },
    },
  },
  {
    id: "k1PSDzp5x5i2cbcbbGTA",
    datasetName: "Dataset name",
    categories: ["NDVI", "environment"],
    description:
      "This is a brief description of this datasets and what it contains",
    sdgGoals: ["goal5"],
    locationLabel: "W5VW+9W Andranofeno, Madagascar",
    locationMap: [
      {
        lng: 46.318359375,
        lat: -16.63619187839765,
      },
      {
        lng: 48.076171875,
        lat: -16.63619187839765,
      },
      {
        lng: 47.197265625,
        lat: -19.476950206488414,
      },
    ],
    dateCompleted: "2022-11-04",
    deliveryFiles: {
      baseFiles: ["https://someURL", "https://someURL", "https://someURL"],
      processedData: {
        _2dMap: ["https://someURL"],
      },
    },
  },
];

export const singleDataLibraryJson = {
  fileDataType: ["_2dMap", "digitalTerrainModel"],
};

export const singleDataLibraryResponseJson = {
  id: "9orj8k23",
  datasetName: "Dataset name",
  categories: ["forestry", "orthomosaics"],
  description:
    "This is a brief description of this datasets and what it contains",
  sdgGoals: ["goal1", "goal2"],
  locationLabel: "Oriximiná - State of Pará, 68270-000, Brazil",
  locationMap: [
    {
      lng: -56.28995416860816,
      lat: -1.1943083787212674,
    },
    {
      lng: -56.20618341665504,
      lat: -1.1805784177529681,
    },
    {
      lng: -56.19245050649879,
      lat: -1.2217680940951903,
    },
    {
      lng: -56.26660822134254,
      lat: -1.2368708183180714,
    },
  ],
  dateCompleted: "2021-02-28",
  deliveryFiles: {
    baseFiles: ["https://someURL", "https://someURL", "https://someURL"],
    processedData: {
      _2dMap: ["https://someURL"],
      digitalTerrainModel: ["https://someURL"],
    },
  },
};
