import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        h1: {
          fontWeight: 500,
        },
        h2: {
          fontWeight: 500,
        },
        h3: {
          fontWeight: 500,
        },
        h4: {
          fontWeight: 500,
        },
        strong: {
          fontWeight: 500,
        },
        b: {
          fontWeight: 500,
        },
      },
    },
    MuiButton: {
      contained: {
        fontSize: 14,
        lineHeight: 16 / 14,
        fontWeight: 500,
        width: 206,
        padding: "12px 24px",
        color: "#4849d3",
        textTransform: "none",
      },
      outlined: {
        textTransform: "none",
      },
    },
  },
  typography: {
    fontFamily: "NHaasGroteskDSPro",
    body1: {
      color: "#101820",
      fontSize: 14,
      lineHeight: 21 / 14,
    },
    h1: {
      fontWeight: 500,
    },
    h2: {
      fontWeight: 500,
    },
    h3: {
      fontWeight: 500,
    },
    h4: {
      fontWeight: 500,
    },
    h5: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 19 / 16,
    },
    h6: {
      color: "#101820",
      fontSize: 24,
      lineHeight: 28 / 24,
      fontWeight: 500,
    },
  },
  palette: {
    black: {
      darkSeaBlack: "#101820",
      coolGrey: "#565E63",
      stoneSilver: "#BCC9C5",
      paleSilver: "#EAF1EF",
    },
    primary: {
      //Lime is main
      main: "#BBF45D",
      pistachio: "#D3FF8D",
      palePistachio: "#E3FFB6",
    },
    secondary: {
      //Deep purple is main
      main: "#6D53EB",
      lightPurple: "#8070ED",
      lavender: "#9B91FF",
      lightLavender: "#B3AFFF",
      paleLavender: "#CCC9FF",
      textColor: "#00A97D",
      clickableText: "#6D53EB",
    },
    grey: {
      white: "#FFFFFF",
      deepWarmGrey: "#606060",
      warmGrey: "#8E8E8E",
      lightWarmGrey: "#D3D3D3",
      dividersColor: "#CCCCCC",
    },
    status: {
      disabledDark: "#ACACAC",
      disabledLight: "#D9D9D9",
      errorDark: "#D75257",
      errorLight: "#F2D1D2",
      warningDark: "#F0AA4B",
      warningLight: "#F7C393",
      successDark: "#18BB76",
      successLight: "#E7EFED",
    },
  },
  spacing: 10,
  shape: {
    borderRadius: 0,
  },
  custom: {
    drawerWidthMobile: 240,
    drawerWidth: 340,
  },
});

export default responsiveFontSizes(theme);
